@font-face {
  font-family: Union;
  src: url("union.0a10dfed.woff2") format("woff2");
}

body {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  hyphens: auto;
  word-wrap: break-word;
  -webkit-user-select: none;
  user-select: none;
  background-color: #000;
  margin: .5em 1em;
  font-family: Union, Helvetica Neue, Helvetica, sans-serif;
  font-size: max(3vw, 3vh);
  line-height: 1.33;
  overflow: hidden;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.root {
  max-width: 66.66%;
  margin: auto;
}

@media screen and (width >= 1280px) {
  .root {
    max-width: 50%;
  }
}

@media screen and (width <= 768px) {
  .root {
    max-width: 100%;
  }
}

@media screen and (orientation: portrait) {
  .root {
    max-width: 100%;
  }
}

.root > :last-child {
  justify-content: center;
  align-items: center;
  gap: .25em;
}

.root > :last-child:after {
  content: "";
  mix-blend-mode: difference;
  background-color: #fff;
  border-radius: 50%;
  width: .5em;
  height: .5em;
  margin-top: .25em;
  animation: 1s infinite pulse;
  display: block;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

span {
  opacity: 0;
  animation: .2s forwards fade-in;
  display: inline-flex;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.status {
  -webkit-user-select: none;
  user-select: none;
  padding: .75em 1em;
  font-size: 16px;
  line-height: 1;
  position: fixed;
  top: 0;
  left: 0;
}

.status--suspended, .status--closed {
  cursor: pointer;
}

.status--running {
  animation: 3s 1s forwards fade-out;
}

/*# sourceMappingURL=index.08201ee5.css.map */
