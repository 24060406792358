@font-face {
  font-family: "Union";
  src: url("./fonts/union.woff2") format("woff2");
}

body {
  background-color: black;
  color: white;
  font-family: "Union", "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.33;
  font-size: max(3vw, 3vh);
  margin: 0.5em 1em;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  hyphens: auto;
  word-wrap: break-word;
  user-select: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.root {
  max-width: 66.66%;
  margin: auto;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  @media screen and (orientation: portrait) {
    max-width: 100%;
  }

  > :last-child {
    align-items: center;
    justify-content: center;
    gap: 0.25em;

    &:after {
      content: "";
      display: block;
      width: 0.5em;
      height: 0.5em;
      background-color: white;
      border-radius: 50%;
      animation: pulse 1s infinite;
      margin-top: 0.25em;
      mix-blend-mode: difference;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

span {
  display: inline-flex;
  opacity: 0;
  animation: fade-in 200ms forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.status {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.75em 1em;
  user-select: none;
  font-size: 16px;
  line-height: 1;

  &--suspended,
  &--closed {
    cursor: pointer;
  }

  &--running {
    animation: fade-out 3s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
